import { Stack, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import InfoLogo from "src/components/info-logo/info-logo";
import { setSigninData } from "src/features/signin/signin.action";
import palette from "src/theme/palette";

 const styleInput = {
	input: { color: palette.common.black },
	width: { lg: 515, md: 446, sm: 446 },
	"& .MuiOutlinedInput-root": {
		"&:hover fieldset": {
			borderColor: "primary.dark",
		},
		"&.Mui-focused fieldset": {
			borderColor: "primary.dark",
		},
	},
	"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
		borderColor: "secondary.light",
	},
	"& .MuiInputLabel-formControl": {
		color: "#00000099",
	},
	"& .MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
		color: "#00000099",
},
};

const LabelGenerationContent = ({ editLabelGenerationFields }) => {
  const signinData = useSelector((state) => state.signin.signinData);
  const dispatch = useDispatch();

  const {
    carrierSignUSPSIsLabelGenerate,
    carrierSignUSPSCRID,
    carrierSignUSPSCRIDError,
    carrierSignUSPSManifestMID,
    carrierSignUSPSManifestMIDError,
    carrierSignUSPSMID,
    carrierSignUSPSMIDError,
    carrierSignUSPSAccountNumber,
    carrierSignUSPSAccountNumberError,
  } = signinData || {};

  return (
    <Stack width={700} gap={3}>
      <Stack direction={"row"} gap={3}>
        <Stack direction={"row"} alignItems={"center"} flex={1}>
          <TextField
            disabled={!carrierSignUSPSIsLabelGenerate}
            sx={{ ...styleInput, width: "100%" }}
            label="CRID *"
            value={carrierSignUSPSCRID}
            error={carrierSignUSPSCRIDError}
            helperText={carrierSignUSPSCRIDError && "CRID is required."}
            onFocus={() =>
              dispatch(
                setSigninData({
                  ...signinData,
                  carrierSignUSPSCRIDError: false,
                })
              )
            }
            onChange={(e) => {
              const trimmedValue = e.target.value.trim();
              dispatch(
                setSigninData({
                  ...signinData,
                  carrierSignUSPSCRID: trimmedValue,
                  continueBtn: false,
                })
              );
            }}
            inputProps={{
              maxLength: 15,
              readOnly: !editLabelGenerationFields,
            }}
          />
          <InfoLogo infoTxt="Enter your USPS CRID number. This is a USPS-generated numeric code of up to 15 digits that uniquely identifies a business at a location." />
        </Stack>

        <Stack direction={"row"} alignItems={"center"} flex={1}>
          <TextField
            disabled={!carrierSignUSPSIsLabelGenerate}
            sx={{ ...styleInput, width: "100%" }}
            label="Manifest ID *"
            value={carrierSignUSPSManifestMID}
            error={carrierSignUSPSManifestMIDError}
            helperText={
              carrierSignUSPSManifestMIDError && "Manifest ID is required."
            }
            onFocus={() =>
              dispatch(
                setSigninData({
                  ...signinData,
                  carrierSignUSPSManifestMIDError: false,
                })
              )
            }
            onChange={(e) => {
              const trimmedValue = e.target.value.trim();
              dispatch(
                setSigninData({
                  ...signinData,
                  carrierSignUSPSManifestMID: trimmedValue,
                  continueBtn: false,
                })
              );
            }}
            inputProps={{
              maxLength: 50,
              readOnly: !editLabelGenerationFields,
            }}
          />
          <InfoLogo infoTxt="Enter your USPS Manifest ID. This is a unique identification to label each piece in a manifest mailing." />
        </Stack>
      </Stack>
      <Stack direction={"row"} gap={3}>
        <Stack direction={"row"} alignItems={"center"} flex={1}>
          <TextField
            disabled={!carrierSignUSPSIsLabelGenerate}
            sx={{ ...styleInput, width: "100%" }}
            label="MID *"
            value={carrierSignUSPSMID}
            error={carrierSignUSPSMIDError}
            helperText={carrierSignUSPSMIDError && "MID is required."}
            onFocus={() =>
              dispatch(
                setSigninData({
                  ...signinData,
                  carrierSignUSPSMIDError: false,
                })
              )
            }
            onChange={(e) => {
              const trimmedValue = e.target.value.trim();
              dispatch(
                setSigninData({
                  ...signinData,
                  carrierSignUSPSMID: trimmedValue,
                  continueBtn: false,
                })
              );
            }}
            inputProps={{
              maxLength: 9,
              readOnly: !editLabelGenerationFields,
            }}
          />
          <InfoLogo infoTxt="Enter your USPS MID number. MIDs are assigned by the USPS to a Mail Owner, Mailing Agent or other service providers who request them." />
        </Stack>

        <Stack direction={"row"} alignItems={"center"} flex={1}>
          <TextField
            disabled={!carrierSignUSPSIsLabelGenerate}
            sx={{ ...styleInput, width: "100%" }}
            label="Account Number *"
            value={carrierSignUSPSAccountNumber}
            error={carrierSignUSPSAccountNumberError}
            helperText={
              carrierSignUSPSAccountNumberError && "Account Number is required."
            }
            onFocus={() =>
              dispatch(
                setSigninData({
                  ...signinData,
                  carrierSignUSPSAccountNumberError: false,
                })
              )
            }
            onChange={(e) => {
              const trimmedValue = e.target.value.trim();
              dispatch(
                setSigninData({
                  ...signinData,
                  carrierSignUSPSAccountNumber: trimmedValue,
                  continueBtn: false,
                })
              );
            }}
            inputProps={{
              maxLength: 50,
              readOnly: !editLabelGenerationFields,
            }}
          />
          <InfoLogo infoTxt="Enter your USPS account number. " />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default LabelGenerationContent;
