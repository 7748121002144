import { Box, Modal, Stack, IconButton, Button } from "@mui/material";
import palette from "src/theme/palette";
import typography from "src/theme/typography";

const popUpModalStyle = {
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: { xs: "80%", sm: "auto", lg: "580px", md: "auto" },
	borderRadius: "20px",
	background: "#FFF",
	overflow: "auto",
	overflow: "auto-y",
	overflowX: "hidden",
	outline: "none",
};

const ModalComponent = ({
	openModal,
	handleCloseModal,
	handleBtnClick,
	title,
	description,
	description2,
	btnText,
	children,
	cancelBtnText,
}) => {
	return (
		<Modal open={openModal} onClose={handleCloseModal}>
			<Box
				sx={{
					...popUpModalStyle,
					paddingY: 2,
					paddingX: 4,
				}}
			>
				<Stack
					gap={3}
					direction="column"
					alignItems={"center"}
					sx={{
						color: "common.black",
						paddingY: 2,
						paddingX: 4,
						fontFamily: typography.fontFamilyRegular,
						backgroundColor: "white",
					}}
				>
					<IconButton
						aria-label="close"
						sx={{
							position: "fixed",
							top: { xs: 13, md: 20 },
							right: { md: 22, xs: 10 },
							zIndex: 2,
						}}
						onClick={handleCloseModal}
					>
						<img
							width="30px"
							src="/assets/icons/table/close.svg"
							alt="X"
						/>
					</IconButton>

					<Box
						sx={{
							fontFamily: typography.fontFamilySemiBold,
							color: palette.secondary.main,
							fontSize: {
								lg: 34,
								md: 27,
								xs: 22,
								sm: 22,
							},
							lineHeight: "normal",
							textAlign: "center",
						}}
					>
						{title}
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: "0.7rem",
						}}
					>
						<Box
							sx={{
								fontSize: {
									lg: 17,
									md: 16,
									xs: 14,
									sm: 14,
								},
								textAlign: "center",
							}}
						>
							{description}
						</Box>
						{description2 && (
							<Box
								sx={{
									fontSize: {
										lg: 17,
										md: 16,
										xs: 14,
										sm: 14,
									},
									textAlign: "center",
								}}
							>
								{description2}
							</Box>
						)}
						{children && (
							<Box
								sx={{
									fontSize: {
										lg: 17,
										md: 16,
										xs: 14,
										sm: 14,
									},
									textAlign: "center",
								}}
							>
								{children}
							</Box>
						)}
					</Box>
					{/* <Button
						fullWidth
						variant="contained"
						disableElevation
						type="submit"
						sx={{
							boxShadow: "none",
							backgroundColor: palette.secondary.main,
							paddingY: 1.3,
							fontSize: {
								lg: 15,
								md: 15,
								xs: 13,
								sm: 13,
							},
						}}
						style={{
							fontFamily: typography.fontFamilySemiBold,
						}}
						onClick={handleBtnClick}
					>
						{btnText}
					</Button> */}
					{cancelBtnText ? (
						<Stack
							direction={"row"}
							gap={2}
							width={"70%"}
							alignItems={"center"}
						>
							<Button
								fullWidth
								variant="contained"
								disableElevation
								sx={{
									boxShadow: "none",
									backgroundColor: "grey.600",
									paddingY: 1.3,
									fontSize: {
										lg: 15,
										md: 15,
										xs: 13,
										sm: 13,
									},
									"&:hover": {
										backgroundColor: "primary.lightIcon",
									},
								}}
								style={{
									fontFamily: typography.fontFamilySemiBold,
								}}
								onClick={handleCloseModal}
							>
								{cancelBtnText}
							</Button>
							<Button
								fullWidth
								variant="contained"
								disableElevation
								type="submit"
								sx={{
									boxShadow: "none",
									backgroundColor: palette.secondary.main,
									paddingY: 1.3,
									fontSize: {
										lg: 15,
										md: 15,
										xs: 13,
										sm: 13,
									},
								}}
								style={{
									fontFamily: typography.fontFamilySemiBold,
								}}
								onClick={handleBtnClick}
							>
								{btnText}
							</Button>
						</Stack>
					) : (
						<Stack
							direction={"row"}
							gap={2}
							minWidth={"30%"}
							maxWidth={"60%"}
						>
							<Button
								fullWidth
								variant="contained"
								disableElevation
								type="submit"
								sx={{
									boxShadow: "none",
									backgroundColor: palette.secondary.main,
									paddingY: 1.3,
									fontSize: {
										lg: 15,
										md: 15,
										xs: 13,
										sm: 13,
									},
								}}
								style={{
									fontFamily: typography.fontFamilySemiBold,
								}}
								onClick={handleBtnClick}
							>
								{btnText}
							</Button>
						</Stack>
					)}
				</Stack>
			</Box>
		</Modal>
	);
};

export default ModalComponent;
