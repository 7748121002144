import postData from "src/dataFetcher/postDataFetcher";
import postFormData from "src/dataFetcher/postFormDataFetcher";
import putData from "src/dataFetcher/putDataFetcher";
import getData from "src/dataFetcher/getDataFetcher";
import axios from "axios";
import { extractData } from "src/dataFetcher/extractData";

import { backendTenantUrl } from "src/utils/backendUrls";

const logoUrl = process.env.REACT_APP_BACKEND_LOGO_URL;

export const retrieveLogoUrl = process.env.REACT_APP_BACKEND_RETRIEVE_LOGO_URL;

export async function updateTenantInfo(data, userInfo, tenant_name, tenant_id) {
	const token = userInfo?.accessToken;
	const header = {
		tenant_id,
		action: "upgradePlan",
		user_id: userInfo.user_id,
	};

	return await postData(`${backendTenantUrl}order`, header, data, token);
}

export async function updateCarrierOrder(
	data,
	userInfo,
	tenant_name,
	tenant_id
) {
	const token = userInfo?.accessToken;
	const header = {
		tenant_id,
	};

	return await putData(
		`${backendTenantUrl}tenant/${tenant_name}/tenant/company_config/${tenant_id}`,
		header,
		data,
		token
	);
}

export async function updateLogo(file, userInfo, tenant_name, headers) {
	const token = userInfo?.accessToken;
	const tenant = userInfo?.tenant_name;

	return await postFormData(
		`${logoUrl}${tenant_name}/tenant/logo_update`,
		headers,
		file,
		token
	);
}

export async function updateEmailPreferences(
	data,
	userInfo,
	tenant_name,
	tenant_id
) {
	const token = userInfo?.accessToken;

	const headers = {
		tenant_id,
		// user_id: userInfo.user_id,
	};

	return await putData(
		`${backendTenantUrl}tenant/${tenant_name}/tenant/config/email_preference/${tenant_id}`,
		headers,
		data,
		token
	);
}

export async function getEmailTemplatesList(userInfo) {
	const headers = {
	  tenant_id: userInfo?.tenant_id,
	  user_id: userInfo?.user_id,
	};
	if (userInfo?.is_demo_user) {
	  headers.is_demo_user = true;
	}
	const token = userInfo?.accessToken;
	const result = await getData(
	  `${backendTenantUrl}tenant/${userInfo?.tenant_name}/return/email_template/info`,
	  null,
	  headers,
	  token
	);
	
	return result;
  }
  
  export async function putEmailTemplatesList(data, userInfo) {
	console.log("putEmailTemplatesList",data, userInfo);
	
	const url = backendTenantUrl;
	const headers = {
	  tenant_id: userInfo?.tenant_id,
	  user_id: userInfo?.user_id,
	};
	if (userInfo?.is_demo_user) {
	  headers.is_demo_user = true;
	}
	const token = userInfo?.accessToken;
	const result = await putData(
	  `${url}tenant/${userInfo?.tenant_name}/return/email_template/info`,
	  headers,
	  data,
	  token
	);
	// return JSON.parse(extractData(result.data));
	return result;
  }
  export async function getEmailTemplatesConfig(userInfo) {
	const headers = {
	  tenant_id: userInfo?.tenant_id,
	  user_id: userInfo?.user_id,
	};
	if (userInfo?.is_demo_user) {
	  headers.is_demo_user = true;
	}
	const token = userInfo?.accessToken;
	const result = await getData(
	  `${backendTenantUrl}tenant/${userInfo?.tenant_name}/return/rules/info/email_template`,
	  null,
	  headers,
	  token
	);
	return result;
  }
  
  export async function sendTestEmail(data, userInfo) {
	const headers = {
	  tenant_id: userInfo?.tenant_id,
	  Authorization: `Bearer ${userInfo?.accessToken}`,
	};
	const res = await axios.post(
	  `https://g81k5i32za.execute-api.us-east-2.amazonaws.com/v1/notification/api/V1/notification/send_raw_content_mail`,
	  data,
	  { headers }
	);
	return res.data;
  }
  export async function GetUsersList(userInfo) {
	const headers = {
	  tenant_id: userInfo?.tenant_id,
	  user_id: userInfo?.user_id,
	};
	if (userInfo?.is_demo_user) {
	  headers.is_demo_user = true;
	}
	const token = userInfo?.accessToken;
	const result = await getData(
	  `${backendTenantUrl}user/frontend/tenant_info/users_list`,
	  null,
	  headers,
	  token
	);
	return result;
  }