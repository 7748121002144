    const fetchHtmlContent = async (htmlPath) => {
    try {
      const response = await fetch(htmlPath);
      const html = await response.text();
      return html;
      // setHtmlContent(html);
    } catch (error) {
      console.error("Error fetching HTML content:", error);
    }
  };
  
  const paths = [
    {
      template_name: "otp_triggered",
      subject: "OTP for your return initiation",
      content_path: "/returnEmailTemplates/contents/otpVerificationContent.txt",
      layout_path: "/returnEmailTemplates/layout/emailTemplateLayout.txt",
    },
    {
      template_name: "request_initiated",
      subject:
        "Your return is initiated. Look out for the return instructions post acceptance.",
      content_path:
        "/returnEmailTemplates/contents/requestedInitiatedContent.txt",
      layout_path: "/returnEmailTemplates/layout/emailTemplateLayout.txt",
    },
    {
      template_name: "request_initiated_customer_support",
      subject: "Return Initialization by CX",
      content_path:
        "/returnEmailTemplates/contents/requestedInitiatedCSContent.txt",
      layout_path: "/returnEmailTemplates/layout/emailTemplateLayout.txt",
    },
    {
      template_name: "free_label",
      subject:
        "Your return has been accepted. Follow the instructions to send it back to us.",
      content_path: "/returnEmailTemplates/contents/freeLabelContent.txt",
      layout_path: "/returnEmailTemplates/layout/emailTemplateLayout.txt",
    },
    {
      template_name: "paid_label",
      subject:
        "Your return has been accepted. Follow the instructions to send it back to us.",
      content_path: "/returnEmailTemplates/contents/paidLabelContent.txt",
      layout_path: "/returnEmailTemplates/layout/emailTemplateLayout.txt",
    },
    {
      template_name: "2_way_label",
      subject:
        "Your return has been accepted. Follow the instructions to send it back to us.",
      content_path: "/returnEmailTemplates/contents/twoWayLabelContent.txt",
      layout_path: "/returnEmailTemplates/layout/emailTemplateLayout.txt",
    },
    {
      template_name: "own_label",
      subject:
        "Your return has been accepted. Follow the instructions to send it back to us.",
      content_path: "/returnEmailTemplates/contents/ownLabelContent.txt",
      layout_path: "/returnEmailTemplates/layout/emailTemplateLayout.txt",
    },
    {
      template_name: "refund_delivery",
      subject: "Refund during delivery Handling Issues",
      content_path: "/returnEmailTemplates/contents/duringDeliveryContent.txt",
      layout_path: "/returnEmailTemplates/layout/emailTemplateLayout.txt",
    },
    {
      template_name: "return_reject",
      subject: "Return has been rejected for {{order_id}}",
      content_path: "/returnEmailTemplates/contents/returnRejectedContent.txt",
      layout_path: "/returnEmailTemplates/layout/emailTemplateLayout.txt",
    },
    {
      template_name: "direct_refund",
      subject: "Your refund has been processed for {{order_id}}",
      content_path: "/returnEmailTemplates/contents/directRefundContent.txt",
      layout_path: "/returnEmailTemplates/layout/emailTemplateLayout.txt",
    },
    {
      template_name: "tracking_number_updated",
      subject: "Thank you for updating the carrier and tracking number.",
      content_path:
        "/returnEmailTemplates/contents/trackingNumberUpdatedContent.txt",
      layout_path: "/returnEmailTemplates/layout/emailTemplateLayout.txt",
    },
    {
      template_name: "update_tracking_number_reminder",
      subject:
        "Reminder for updating the carrier and tracking number for your return.",
      content_path:
        "/returnEmailTemplates/contents/updateTrackingNumberReminder.txt",
      layout_path: "/returnEmailTemplates/layout/emailTemplateLayout.txt",
    },
    //--------delivery tracking management
    {
      template_name: "first_scan_email",
      subject: "Your package is on its way. The carrier has scanned your shipment.",
      content_path: "/returnEmailTemplates/contents/carrierOriginScanContent.txt",
      layout_path: "/returnEmailTemplates/layout/emailTemplateLayout.txt",
    },
    {
      template_name: "out_for_delivery_email",
      subject: "Exciting news! Your package is out for delivery.",
      content_path: "/returnEmailTemplates/contents/outForDeliveryContent.txt",
      layout_path: "/returnEmailTemplates/layout/emailTemplateLayout.txt",
    },
    {
      template_name: "delivered_email",
      subject: "Great! Your package has been delivered.",
      content_path: "/returnEmailTemplates/contents/deliveredEmailContent.txt",
      layout_path: "/returnEmailTemplates/layout/emailTemplateLayout.txt",
    },
    
  ];
  
  export const htmlReturnTemplateTextList = async () => {
    const htmlTextContentList = [];
  
    for (let i = 0; i < paths.length; i++) {
      htmlTextContentList.push({
        template_name: paths[i].template_name,
        subject: paths[i].subject,
        html_content: await fetchHtmlContent(paths[i].content_path),
        html_layout: await fetchHtmlContent(paths[i].layout_path),
      });
    }
  
    return htmlTextContentList;
  };
  
  export const fetchTemplateByName = async (template_name) => {
    const selectedTemplate = paths.find(
      (template) => template.template_name === template_name
    );
  
    if (selectedTemplate) {
      return {
        template_name: selectedTemplate.template_name,
        subject: selectedTemplate.subject,
        html_content: await fetchHtmlContent(selectedTemplate.content_path),
        html_layout: await fetchHtmlContent(selectedTemplate.layout_path),
      };
    } else {
      throw new Error(`Template with name "${template_name}" not found`);
    }
  };
  