import getData from "src/dataFetcher/getDataFetcher";
import postData from "src/dataFetcher/postDataFetcher";
import { backendTrackingUrl } from "src/utils/backendUrls";


export default async function getTrackingList(params, userInfo, headers) {
	const token = userInfo?.accessToken;
	const result = await getData(
		`${backendTrackingUrl}tracking/tracking-info`,
		params,
		headers,
		token
	);
	return result;
}

export async function getAverageTrackingList(params, userInfo) {
	const headers = {
		tenant_id: userInfo?.tenant_id,
		user_id: userInfo?.user_id,
	};
	const token = userInfo?.accessToken;
	const result = await getData(
		`${backendTrackingUrl}reports/average_intransit_time`,
		params,
		headers,
		token
	);
	return result;
}
export async function getInTransitAlertTrackingList(params, userInfo) {
	const headers = {
		tenant_id: userInfo?.tenant_id,
		user_id: userInfo?.user_id,
	};
	const token = userInfo?.accessToken;
	const result = await getData(
		`${backendTrackingUrl}reports/in-transit-alert`,
		params,
		headers,
		token
	);
	return result;
}

export async function getTrackingInfo(tracking_number, params, userInfo, headers) {
	const token = userInfo?.accessToken;
	const result = await getData(
		`${backendTrackingUrl}tracking/tracking-info/${tracking_number}`,
		params,
		headers,
		token
	);
	return result;
}
export async function sendTrackInformationToRefresh(data, userInfo, headers) {
	const token = userInfo?.accessToken;
	const result = await postData(
		`${backendTrackingUrl}tracking/tracking/refresh`,
		headers,
		data,
		token
	);
	return result;
}
