import { Box, Button, FormControlLabel, Input, OutlinedInput, Switch, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../hooks/hooks";
import { getUserInfo } from "../../features/user/userState";
import { updateTenant } from "../../features/tenants/tenantsApi"
import { useDispatch, useSelector } from "react-redux";
import ModalComponent from "src/components/modal/ModalComponent";
import { Controller, useForm } from "react-hook-form";
import { decrementAwaitingApiCounter, incrementAwaitingApiCounter } from "src/features/ui/uiState";
import palette from "src/theme/palette";

const PricingConfiguration = ({ tenantData, getTenantById }) => {
    const dispatch = useDispatch()
    const { control, handleSubmit, reset, formState: { errors } } = useForm({ mode: "onChange" });
    const userInfo = useAppSelector(getUserInfo);
    const [isCustomPlan, setIsCustomPlan] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isStatusModal, setIsStatusModal] = useState(false)

    useEffect(() => {
        reset({
            custom_track_price: +tenantData?.custom_track_price || 0.06,
            custom_return_price: +tenantData?.custom_return_price || 0.30,
            custom_track_limit: +tenantData?.custom_track_limit || 1000,
            custom_return_limit: +tenantData?.custom_return_limit || 100,
            custom_plan_price: +tenantData?.custom_plan_price || 99,
        })
        setIsCustomPlan(tenantData?.is_custom_plan)
    }, [])

    const filteredTenant = useSelector(
        (state) => state?.tenants
    );

    async function handleSubmits(data) {
        try {

            dispatch(incrementAwaitingApiCounter());
            let { custom_track_price, custom_return_price, custom_track_limit, custom_return_limit, custom_plan_price } = data
            let formData = {
                ...tenantData,
                custom_track_price: +custom_track_price,
                custom_return_price: +custom_return_price,
                custom_track_limit: +custom_track_limit,
                custom_return_limit: +custom_return_limit,
                custom_plan_price: +custom_plan_price,
                is_custom_plan: isCustomPlan
            }
            let headers = {
                tenant_id: filteredTenant?.tenant_id,
                user_id: filteredTenant.user_id,
            };
            let response = await updateTenant(formData, userInfo, headers)
            if (response?.status == 200) {
                dispatch(decrementAwaitingApiCounter());
                setIsStatusModal(true)
                getTenantById()
            }
        } catch (err) {
            dispatch(decrementAwaitingApiCounter());
        }
    }

    const handleCustomPlanChange = () => {
        setIsModalOpen(true)
    }

    const styleInput = {
        input: { color: palette.common.black },
        width: { lg: "100%" },
        marginTop: "10px",
        "& .MuiOutlinedInput-root": {
            "&:hover fieldset": {
                borderColor: "primary.dark",
            },
            "&.Mui-focused fieldset": {
                borderColor: "primary.dark",
            },
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "secondary.light",
        },
        "& .MuiInputLabel-formControl": {
            color: "#00000099",
        },
        "& .MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
            color: "#00000099",
        },
        "& .MuiFormHelperText-root": {
            textAlign: "left",
            fontSize: "14px",
        },
    };

    return (
        <div>
            <Box>
                <FormControlLabel control={<Switch checked={isCustomPlan} />} onChange={() => !isCustomPlan ? handleCustomPlanChange() : setIsCustomPlan(false)} label="Enable custom plan" sx={{ color: "#000" }} />
            </Box>
            <Box width={210}>
                <Controller
                    name="custom_plan_price"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Required" }}
                    render={({ field, fieldState }) => (
                        <>
                            <TextField
                                sx={styleInput}
                                label="Plan Price"
                                {...field}
                                type="number"
                                size="small"
                                disabled={!isCustomPlan}
                            />
                        </>
                    )}
                />
                <p style={{ color: "red" }}>{errors?.custom_plan_price?.type && errors?.custom_plan_price?.message}</p>
            </Box>
            <Box display={"flex"} gap={3} mt={2}>
                <Box>
                    <Controller
                        name="custom_track_limit"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Required" }}
                        render={({ field, fieldState }) => (
                            <>
                                <TextField
                                    sx={styleInput}
                                    label="Tracking Limit"
                                    {...field}
                                    type="number"
                                    size="small"
                                    disabled={!isCustomPlan}
                                />
                            </>
                        )}
                    />
                    <p style={{ color: "red" }}>{errors?.custom_track_limit?.type && errors?.custom_track_limit?.message}</p>
                </Box>
                <Box>
                    <Controller
                        name="custom_track_price"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Required" }}
                        render={({ field, fieldState }) => (
                            <>
                                <TextField
                                    sx={styleInput}
                                    {...field}
                                    label="Tracking Price"
                                    type="number"
                                    size="small"
                                    disabled={!isCustomPlan}
                                />
                            </>
                        )}
                    />
                    <p style={{ color: "red" }}>{errors?.custom_track_price?.type && errors?.custom_track_price?.message}</p>

                </Box>
            </Box>
            <Box display={"flex"} gap={3} mt={2}>
                <Box>
                    <Controller
                        name="custom_return_limit"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Required" }}
                        render={({ field, fieldState }) => (
                            <>
                                <TextField
                                    sx={styleInput}
                                    {...field}
                                    label="Return Limit"
                                    type="number"
                                    size="small"
                                    disabled={!isCustomPlan}
                                />
                            </>
                        )}
                    />
                    <p style={{ color: "red" }}>{errors?.custom_return_limit?.type && errors?.custom_return_limit?.message}</p>

                </Box>
                <Box>
                    <Controller
                        name="custom_return_price"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Required" }}
                        render={({ field, fieldState }) => (
                            <>
                                <TextField
                                    sx={styleInput}
                                    {...field}
                                    label="Return Price"
                                    type="number"
                                    size="small"
                                    disabled={!isCustomPlan}
                                />
                            </>
                        )}
                    />
                    <p style={{ color: "red" }}>{errors?.custom_return_price?.type && errors?.custom_return_price?.message}</p>

                </Box>
            </Box>
            <Box mt={2}>
                <Button sx={{ width: "130px" }} onClick={handleSubmit(handleSubmits)} variant="contained">Save</Button>
            </Box>
            <ModalComponent openModal={isStatusModal} handleBtnClick={() => { setIsStatusModal(false) }} handleCloseModal={() => setIsStatusModal(false)} btnText={"Done"}>
                <h4>Pricing Updated Successfully</h4>
            </ModalComponent>
            <ModalComponent openModal={isModalOpen} title="Are you sure you want to enable custom price" btnText={"Confirm"} handleCloseModal={() => setIsModalOpen(false)} handleBtnClick={() => { setIsCustomPlan(true); setIsModalOpen(false) }} />
        </div>
    )
}
export default PricingConfiguration;