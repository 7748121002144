export const emailConfigurationTexts = {
    
    delveryEmailTemplateArr: [
      {
        templateName: "first_scan_email",
        title: "Carrier Origin Scan",
        subtitle:
          "When a customer initiates a return order, a One-Time Password (OTP) will be sent to their registered email address for return verification. This step ensures the secure processing of the return request.",
        button1Text: "Edit template",
        button2Text: "Preview",
      },
      {
        templateName: "out_for_delivery_email",
        title: "Out For Delivery",
        subtitle:
          "This email confirms that the customer's return order has been well received and is being successfully initiated.",
        button1Text: "Edit template",
        button2Text: "Preview",
      },
      {
        templateName: "delivered_email",
        title: "Delivered Email",
        subtitle:
          "This email confirms the acceptance of the return order and provides detailed instructions for the customer to return their package.",
        button1Text: "Edit template",
        button2Text: "Preview",
      },]
  };
  