import { Stack, Box, Modal, IconButton, CircularProgress } from '@mui/material';

import { useState } from 'react';
import CircularLoader from 'src/loader/circular/CircularLoader';
import typography from 'src/theme/typography';

const popUpModalStyle = {
  // margin: { lg: '99px 157px 278px 157px' },
  position: 'absolute',
  top: { lg: '50%', md: '50%', sm: '49%' },
  left: { lg: '49%', md: '52%', sm: '50%' },
  transform: 'translate(-50%, -50%)',
  width: "auto",
  height: { lg: '90%', md: '65%', sm: '65%' },
  borderRadius: '20px',
  background: '#FFF',
  padding: '10px 0',
  overflow: 'auto',
  overflow: 'auto-y',
  overflowX: 'hidden',
  outline: 'none',
};

const ModalRowDetails = ({ children, inputData, onExpandhandler, showLoader }) => {
  // TODO: make this id conditionally according to the url
  const id = inputData?.tracking_number || inputData?.id;

  const [openModal, setOpenModal] = useState(true);
  const handleCloseModal = (e) => {
    setOpenModal(false);
    onExpandhandler(e, id);
  };

  return showLoader ? (
    <CircularLoader />
  ):(
    <Modal open={openModal} onClose={handleCloseModal}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          ...popUpModalStyle,
        }}
      >
          <Stack
            direction="row"
            spacing={{ lg: 8, sm: 2, md: 2 }}
            sx={{
              color: '#000',
              padding: 4,
              fontSize: { sm: 14, lg: 16 },
              fontFamily: typography.fontFamilyRegular,
              backgroundColor: 'white',
              margin: 'auto',
            }}
          >
            <IconButton
              aria-label="close"
              sx={{
                position: 'fixed',
                top: { sm: 15, lg: 34 },
                right: { lg: 29, md: 30, sm: 10 },
                zIndex: 2,
              }}
              onClick={handleCloseModal}
            >
              <img width="34px" src="/assets/icons/table/close.svg" alt="X" />
            </IconButton>

            {children}
          </Stack>
      </Box>
    </Modal>
  );
};

export default ModalRowDetails;
