import { LoadingButton } from "@mui/lab";
import { FormControl, Stack, TextField, Typography,Box } from "@mui/material";
import { useEffect, useState, useCallback } from "react";
import { debounce } from "lodash";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { getUserInfo, updateUserInfo } from "../../features/user/userState";
import { useDispatch, useSelector } from "react-redux";
import {
	incrementAwaitingApiCounter,decrementAwaitingApiCounter,} from "src/features/ui/uiState";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks"
// import {
//   putEmailTemplatesList,
//   sendTestEmail,
// } from "src/features/returnConfiguration/emailTemplatesApi";
// import {
//   decrementAwaitingApiCounter,
//   incrementAwaitingApiCounter,
// } from "src/features/ui/uiState";
// import { getUserInfo } from "src/features/user/userState";
// import { useAppSelector, useAppDispatch } from "src/hooks/hooks";
import typography from "src/theme/typography";
import PreviewTemplate from "./PreviewTemplate";
import ModalComp from "./ModalComp";
import ModalComponent from "src/components/modal/ModalComponent";
import { retrieveLogoUrl,sendTestEmail } from "src/features/config/configApi";
import { useForm } from "react-hook-form";
import palette from "src/theme/palette";
// import "./styles.css";
import {
  filterImgs,
  getEmailTemplate,
  mergeHtmlLayoutAndNewContent,
  publishEmailTemplate,
} from "src/utils/emailTemplates/functions";

export const filterTable = (htmlContent) => {
  const temporalComp = document.createElement("div");
  temporalComp.innerHTML = htmlContent;
  const unwantedTable = temporalComp.getElementsByTagName("table");
  for (let i = 0; i < unwantedTable.length; i++) {
    unwantedTable[i].parentNode.removeChild(unwantedImgs[i]);
  }

  return temporalComp.innerHTML;
};
const styleInput = {
	input: { color: palette.common.black },
	// width: { lg: 515, md: 446, sm: 446 },
	"& .MuiOutlinedInput-root": {
		"&:hover fieldset": {
			borderColor: "primary.dark",
		},
		"&.Mui-focused fieldset": {
			borderColor: "primary.dark",
		},
	},
	"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
		borderColor: "secondary.light",
	},
	"& .MuiInputLabel-formControl": {
		color: "#00000099",
	},
	"& .MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
		color: "#00000099",
},
};
const EmailTemplateEdition = ({
  template_name,
  setOpenEditTemplateUI,
  emailTemplateItemData,
  logoFile,
  setRefresh,
  emailTemplateLayout,
  setEmailTemplateLayout,
  emailTemplateContent,
  setEmailTemplateContent,
  emailTemplateSubject,
  setEmailTemplateSubject,
  emailTemplateBackendReponse,
  setEmailTemplateBackendReponse,
}) => {
  
  const [openPublishModal, setOpenPublishModal] = useState(false);
  const [openSuccesfullyPublish, setOpenSuccesfullyPublish] = useState(false);
  const [openSuccesfullySentTestMail, setOpenSuccesfullySentTestMail] =
    useState(false);
  const { tenant_id, tenant_name,user_id,is_demo_user } = useSelector((state) => state?.tenants);
  const [isSubmited, setIsSubmited] = useState(false);
  const [sendEmailErrorMessage, setSendEmailErrorMessage] = useState("");


  const dispatch = useAppDispatch();
  const userInfo = useAppSelector(getUserInfo);
  const enhancedUserInfo = {
    accessToken: userInfo?.accessToken,
    tenant_id,
    tenant_name,
    user_id,
    is_demo_user,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"], 
    ["link"],
    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
    [{ indent: "-1" }, { indent: "+1" }], 
    [{ direction: "rtl" }],
    [{ color: [] }, { background: [] }], 
    [{ align: [] }],
    [{ size: ["small", false, "large", "huge"] }], 
  ];
  const module = {
    toolbar: toolbarOptions,
  };

  const handleCancelBtn = () => {
    setOpenEditTemplateUI("");
    setRefresh((prev) => !prev);
  };

  const handlePublishBtn = () => {
    setOpenPublishModal(true);
  };

  const debouncedHandleChange = useCallback(
    debounce((value) => {
      setEmailTemplateContent(value);
    }, 500),
    []
  );

  useEffect(() => {
    if (template_name) {
      getEmailTemplate(
        enhancedUserInfo,
        template_name,
        setEmailTemplateLayout,
        setEmailTemplateSubject,
        setEmailTemplateContent,
        setEmailTemplateBackendReponse,
        emailTemplateBackendReponse,
        dispatch
      );
    }
  }, [template_name]);

  const handleAcceptPublishBtn = async () => {
    // dispatch(incrementAwaitingApiCounter());
    try {
      const response = await publishEmailTemplate(
        enhancedUserInfo,
        template_name,
        emailTemplateLayout,
        emailTemplateSubject,
        emailTemplateContent,
        retrieveLogoUrl,
        logoFile
      );
      if (response.status === 200) {
        setOpenPublishModal(false);
        setOpenSuccesfullyPublish(true);
      }
      dispatch(decrementAwaitingApiCounter());
    } catch (error) {
      dispatch(decrementAwaitingApiCounter());
    }
  };

  const handleSendTestMail = async (data) => {
    const htmlTable = await fetchTableTemplate();

    const body = {
      to_address: [data.email],
      subject: emailTemplateSubject,
      content: mergeHtmlLayoutAndNewContent(
        emailTemplateLayout,
        emailTemplateContent,
        retrieveLogoUrl,
        logoFile
      ).replace("{{product_items}}", htmlTable),
      source: "no-reply@beyondcarts.com",
    };

    try {
      dispatch(incrementAwaitingApiCounter());

      const response = await sendTestEmail(body, enhancedUserInfo);
      if (response.statusCode === 200) {
        setIsSubmited(true);
        setOpenSuccesfullySentTestMail(true);
      } else {
        setSendEmailErrorMessage("❌ There was an error sending the email");
        setIsSubmited(false);
      }

      dispatch(decrementAwaitingApiCounter());
    } catch (error) {
      console.log("error", error);
      setSendEmailErrorMessage(
        "❌ You have exceeded the number of attempts or your email is not registered"
      );
      dispatch(decrementAwaitingApiCounter());
    }
  };

  const fetchTableTemplate = async () => {
    let response;
    if (template_name === "request_initiated") {
      response = await fetch(
        "/returnEmailTemplates/contents/tableTemplateContentWithoutPricing.txt"
      );
    } else if (template_name === "free_label") {
      response = await fetch(
        "/returnEmailTemplates/contents/tableTemplateFreeLabel.txt"
      );
    } else if (template_name === "paid_label") {
      response = await fetch(
        "/returnEmailTemplates/contents/tableTemplatePaidLabel.txt"
      );
    } else if (template_name === "own_label") {
      response = await fetch(
        "/returnEmailTemplates/contents/tableTemplateOwnLabel.txt"
      );
    } else if (template_name === "out_for_delivery_email") {
      response = await fetch(
        "/returnEmailTemplates/contents/tableTemplateOutForDelivery.txt"
      );
    } else if (template_name === "first_scan_email") {
      response = await fetch(
        "/returnEmailTemplates/contents/tableTemplateOutForDelivery.txt"
      );
    } else {
      response = await fetch(
        "/returnEmailTemplates/contents/tableTemplateContent.txt"
      );
    }

    return response.text();
  };

  useEffect(() => {
    const transformContent = async () => {
      if (emailTemplateContent) {
        if (document?.querySelector("#email_template_content")) {
          const htmlTable = await fetchTableTemplate();
          document.querySelector("#email_template_content").innerHTML =
            emailTemplateContent?.replace("{{product_items}}", htmlTable);
        }
      }
    };

    transformContent();
  }, [emailTemplateContent, emailTemplateBackendReponse]);

  return (
    <>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        paddingTop={3}
      >
        <Typography fontSize={20} fontFamily={typography.fontFamilySemiBold}  style={{color: "black"}}>
          Template Name:{" "}
          {
            emailTemplateItemData?.find(
              (el) => el?.templateName === template_name
            )?.title
          }
        </Typography>
        <Stack direction={"row"} gap={2}>
          <LoadingButton
            variant="outlined"
            onClick={handleCancelBtn}
            sx={{
              width: { lg: 140, sm: 120, xs: 120, md: 140 },
              height: { xs: 36, sm: 36, md: 36, lg: 40 },
              border: "1px rgba(128, 128, 128, 0.32) solid",
              color: "secondary.main",
            }}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            variant="contained"
            onClick={handlePublishBtn}
            sx={{
              width: { lg: 140, sm: 120, xs: 120, md: 140 },
              height: { xs: 36, sm: 36, md: 36, lg: 40 },
              backgroundColor: "secondary.main",
              boxShadow: "none",
            }}
          >
            Publish
          </LoadingButton>
        </Stack>
      </Stack>
      <Stack display={"flex"} direction={"row"} width={"100%"} gap={3} paddingY={3}>
        <Stack
          height={"100%"}
          flex={1}
          bgcolor={"#fff"}
          borderRadius={"12px"}
          border="1px solid #AAAAAA"
          padding={2}
          gap={1.5}
          width={"40%"}
        >
          <Typography fontFamily={typography.fontFamilySemiBold} fontSize={14}  style={{color: "black"}}>
            Edit template
          </Typography>
          <Typography fontFamily={typography.fontFamilySemiBold} fontSize={18}  style={{color: "black"}}>
            Subject
          </Typography>
          <TextField
          InputProps={{
            style: { color: "black" }, 
          }}
          InputLabelProps={{
            style: { color: "black" }, 
          }}
            size="small"
            value={emailTemplateSubject}
            onChange={(event) => setEmailTemplateSubject(event.target.value)}
          />
          <Typography fontFamily={typography.fontFamilySemiBold} fontSize={18}
           style={{color: "black"}}
          >
            Message body
          </Typography>

          <ReactQuill
            modules={module}
            theme="snow"
            value={emailTemplateContent}
            onChange={debouncedHandleChange}
          />
        </Stack>
        <Stack
          // height={"200%"}
          flex={1}
          bgcolor={"#fff"}
          borderRadius={"12px"}
          border="1px solid #AAAAAA"
          padding={2}
          gap={1.5}
          width={"60%"}

        >
          <Typography fontFamily={typography.fontFamilySemiBold} fontSize={14}  style={{color: "black"}}>
            Preview template
          </Typography>

          <PreviewTemplate
            template_name={template_name}
            logoFile={logoFile}
            emailTemplateContent={emailTemplateBackendReponse}
            emailTemplateSubject={emailTemplateSubject}
            
          />
        </Stack>
      </Stack>
      <Box>
      <Stack gap={1}>
        <Typography style={{color:'black'}} fontSize={20} paddingBottom={2} >Test your template</Typography>
        <FormControl
          component="form"
          className="form"
          onSubmit={handleSubmit(handleSendTestMail)}
        >
          <Stack direction={"row"} gap={2} >
            <TextField
              size="small"
              placeholder="Enter an email to test your changes"
              sx={{
                width: "32%",
                padding:'5px',
                ...styleInput
              }}
              autoComplete="email"
              {...register("email", {
                required: "Email address is required.",
                pattern: {
                  value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                  message: "Invalid email address.",
                },
              })}
              error={!!errors.email}
              aria-invalid={!!errors.email}
              helperText={
                errors.email
                  ? errors.email.message
                  : isSubmited
                  ? ""
                  : sendEmailErrorMessage
              }
            />
            <LoadingButton
              variant="contained"
              type="submit"
              sx={{
                width: { lg: 140, sm: 120, xs: 120, md: 140 },
                height: { xs: 36, sm: 36, md: 36, lg: 40 },
                backgroundColor: "secondary.main",
                boxShadow: "none",
              }}
            >
              Send test mail
            </LoadingButton>
          </Stack>
        </FormControl>
      </Stack>
      </Box>

      {openPublishModal && (
        <ModalComp
          openModal={openPublishModal}
          handleCloseModal={() => setOpenPublishModal(false)}
          handlePublishBtn={handleAcceptPublishBtn}
          handleCancelBtn={() => setOpenPublishModal(false)}
          title={"Email Template"}
          description={
            "Are you sure you want to publish the changes you have made?"
          }
        />
      )}

      {openSuccesfullyPublish && (
        <ModalComponent
          openModal={openSuccesfullyPublish}
          handleCloseModal={() => setOpenSuccesfullyPublish(false)}
          handleBtnClick={() => {
            setOpenSuccesfullyPublish(false);
            setOpenEditTemplateUI("");
          }}
          title={"Email Template"}
          description={"Your Email Template has been updated successfully"}
          btnText={"Close"}
        />
      )}

      {openSuccesfullySentTestMail && (
        <ModalComponent
          openModal={openSuccesfullySentTestMail}
          handleCloseModal={() => setOpenSuccesfullySentTestMail(false)}
          handleBtnClick={() => setOpenSuccesfullySentTestMail(false)}
          title={"Test Email Template"}
          description={"Your Email Template has been sent to your email."}
          btnText={"Close"}
        />
      )}
    </>
  );
};

export default EmailTemplateEdition;
